var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","color":"accent rounded-0"}},[_c('v-row',[(_vm.batches)?_c('v-col',{attrs:{"md":"2"}},[_c('box-regular',{attrs:{"title":_vm.$t('batch-list.title'),"lot":Number(_vm.batches.count)}})],1):_vm._e()],1)],1),(_vm.batches)?_c('section',[_c('v-data-table',{staticClass:"custom-table ma-5 mt-16",attrs:{"headers":_vm.headers,"items":_vm.batches.results,"items-per-page":_vm.limit,"page":_vm.page,"server-items-length":_vm.batches.count,"footer-props":{
        itemsPerPageText: '',
        itemsPerPageOptions: [],
      },"hide-default-footer":true,"dense":""},on:{"update:page":[function($event){_vm.page=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"item.batch_number",fn:function(props){return [_c('router-link',{attrs:{"to":{
            name: 'batch-profile',
            params: { id: props.item.batch_number },
          }}},[_vm._v(" "+_vm._s(props.item.batch_number)+" ")])]}},{key:"item.stagetype",fn:function(props){return [_vm._v(" "+_vm._s(props.item.stages[props.item.stages.length - 1].stageType)+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/Icon-download.svg")},on:{"click":function($event){return _vm.downloadCsv(item.batch_number)}}})])]}}],null,true)}),_c('v-row',{staticClass:"d-flex"},[(_vm.batches.count)?_c('v-col',{staticClass:"mx-auto mb-6",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"w-100"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.batches.count / _vm.limit)},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }