<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2" v-if="batches">
          <box-regular
            :title="$t('batch-list.title')"
            :lot="Number(batches.count)"
          ></box-regular>
        </v-col>
      </v-row>
    </v-card>

    <section v-if="batches">
      <v-data-table
        class="custom-table ma-5 mt-16"
        :headers="headers"
        :items="batches.results"
        :items-per-page="limit"
        :page.sync="page"
        :server-items-length="batches.count"
        @update:page="paginate"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageOptions: [],
        }"
        :hide-default-footer="true"
        dense
      >
        <template v-slot:[`item.batch_number`]="props">
          <router-link
            :to="{
              name: 'batch-profile',
              params: { id: props.item.batch_number },
            }"
          >
            {{ props.item.batch_number }}
          </router-link>
        </template>
        <template v-slot:[`item.stagetype`]="props">
          {{ props.item.stages[props.item.stages.length - 1].stageType }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn text small>
            <img
              style="cursor: pointer"
              src="@/assets/images/Icon-download.svg"
              @click="downloadCsv(item.batch_number)"
            />
          </v-btn>
        </template>
      </v-data-table>

      <v-row class="d-flex">
        <v-col class="mx-auto mb-6" cols="4" v-if="batches.count">
          <v-container class="w-100">
            <v-pagination
              v-model="page"
              :length="Math.ceil(batches.count / limit)"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/dist/moment";
import i18n from "@/i18n";

export default {
  name: "batch-list",
  async mounted() {
    this.fetchBatchList({ limit: this.limit, offset: 0 });
  },
  data() {
    return {
      username: "",
      reader: "",
      startDate: null,
      endDate: null,
      page: 1,
      limit: 10,
      headers: [
        { text: i18n.tc("label.lot", 2), value: "batch_number" },
        { text: i18n.t("label.reception_date"), value: "reception_date" },
        { text: i18n.t("label.finish_date"), value: "end_date" },
        { text: i18n.t("label.manufacturer"), value: "manufacturer" },
        { text: i18n.t("label.description"), value: "description" },
        { text: i18n.t("label.reference"), value: "reference" },
        { text: i18n.t("label.state"), value: "stagetype" },
        { text: i18n.t("label.action"), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchBatchList: "batchList/fetchBatchList",
      downloadCsv: "batchList/downloadCsv",
    }),

    paginate(val) {
      this.page = val - 1;
      this.fetchBatchList({
        limit: this.limit,
        offset: this.page * this.limit,
      });
      this.page = val;
    },

    getLot() {
      return this.batches?.results?.length > 0
        ? this.batches.results[0].batch_number
        : 0;
    },

    formattedDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  computed: {
    ...mapGetters({
      batches: "batchList/getBatchList",
    }),
  },
};
</script>

<style></style>
